* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --primary-color: #0E4BF1;
    --panel-color: #FFF;
    --text-color: #000;
    --black-light-color: #707070;
    --border-color: #e6e5e5;
    --toggle-color: #DDD;
    --box1-color: #4DA3FF;
    --box2-color: #FFE6AC;
    --box3-color: #E7D1FC;
    --title-icon-color: #fff;
    --tran-05: all 0.5s ease;
    --tran-03: all 0.3s ease;
    --tran-03: all 0.2s ease;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #b3b1b18f, #b3b1b18c);
    z-index: 9999999;
}

#preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #007bff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.candidate-menu-item:hover>.candidate-menu {
    display: block;
}

.breadcrumb {
    --bs-breadcrumb-divider: '>';
}

.Toastify__toast-container--top-right {
    z-index: 999999 !important;
}

.input-validation-error {
    border-color: red !important;
}

.login-form {
    padding: 30px 20px;
}

.bg-blue {
    background: #2457A8 !important;
}

.bg-blue:hover {
    background: #fff !important;
    border-color: #2457A8 !important;
    color: #2457A8 !important;
}

.table i {
    cursor: pointer;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.table i .fa-trash {
    --bs-text-opacity: 1;
    color: rgba(220, 53, 69, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.user-box {
    color: rgba(255, 255, 255, 0.55);
}

.right-nav {
    line-height: 0;
}

.navbar {
    padding: 0;
}

.logo {
    height: 95px;
}

.table input {
    min-width: 65px;
}

.table input[type=checkbox] {
    min-width: unset !important;
}

.table td:nth-last-child(2) input {
    min-width: 80px;
}

.table select {
    min-width: 300px;
}

.dashboard-list {
    height: 350px;
    overflow-x: auto;
}

.table .rbt {
    min-width: 300px;
    position: unset !important;
}

.summary-table {
    font-size: 12px;
}